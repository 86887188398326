import React from "react";
import DocumentsVault from "../../Admin/DocumentsVault";


function CreatorDocumentsVaultPage() {
    return (
        <DocumentsVault isCreator={true}/>
    )
}

export default CreatorDocumentsVaultPage;
